import { useState, useEffect } from 'react'
import Breadcrumb from '../../components/breadcrumb/index'
import DataTable_Component from '../../components/DataTable/index'
import Btn_grp from '../../components/bankPayment/payment_btn_grp/index'
import Details_modal from '../../components/bankPayment/details_modal/index'
import { btn_link } from "./btn_link/btn_link";

// store
import { apidata } from './store/store'

export default function RefundPayment() {

    const title="Bank Refund payment"
    const [data, setData] = useState([])
    const [columnState, setcolumns] = useState([])
    {/* all data for view */ }
    const [selectVal_details, setSelectVal_details] = useState([])
    {/* see all details modal(view) */ }
    const [details_modal_show, set_details_modal_Show] = useState(false);
    const [view_id, setViewId] = useState();
    const [rerendarApi, setRerendarApi] = useState(true)


    const brad = [
        {
            name: "home",
        },
        {
            name: "refunded-payments",
        }
    ]

    const columns = [
        {
            name: "Brand",
            selector: row => row.brand,
            sortable: true
        },
        {
            name: "Full Name",
            selector: row => row.name,
            sortable: true
        },
        {
            name: "Payment Method",
            selector: row => row.payment_method,
            sortable: true
        },
        {
            name: "Amount",
            selector: row => row.amount,
            sortable: true
        },
        {
            name: "Charged Amount",
            selector: row => row.amount,
            sortable: true
        },
        {
            name: "Redunded Amount",
            selector: row => row.refunded_amount,
            sortable: true
        },
        {
            name: "Date",
            selector: row => row.date,
            sortable: true
        }, 
        
        {
            name: "action",
            cell: (row) => <><button onClick={() => handleShow_view_modal(row)} className=" btn btn-primary btn-sm"  >view</button></>,
        }

    ]

    // show view modal;
    const handleShow_view_modal = (row) => {
        setViewId(row.id)
        setSelectVal_details(row)
        set_details_modal_Show(details_modal_show => !details_modal_show);
    }
  

    useEffect(() => {
        // call api and response data set " setApiData(your res.data) " and column setcolumns( columns )
        setData(apidata)
        setcolumns(columns)
    }, [rerendarApi])
    const refund_data = data.filter((val) => {
        return val.status == "refunded"
    })
    const rerender = (e) => {
        setRerendarApi(!rerendarApi)
    }
    return (
        <>
            <Details_modal rerendar={(e) => rerender(e)} view_id={view_id} row={selectVal_details} openDetailsModal={details_modal_show} />
            <div className="container-fluid" >
                <Breadcrumb title={title} brad={brad} />
                <Btn_grp active_btn="refund" btn_link={btn_link} />
                <div className="row my-3">
                    <div className="col-12">
                        <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
                            <div className="card-header d-flex justify-content-between border-bottom pb-1">
                               <h4>{title}</h4>                    
                            </div>
                            <div className="card-body">
                               
                                <DataTable_Component  search="name"  title={title} apidata={refund_data} columns={columnState} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}