import React from 'react';
import styled from 'styled-components';

const Features = () => {
  return (
    <Section>
      <h1>Features</h1>
      <ul>
        <li>
          <h3>Member Management</h3>
          <p>Easily add, manage, and track your members.</p>
        </li>
        <li>
          <h3>Dashboard Analytics</h3>
          <p>Gain insights on pending renewals and reminders.</p>
        </li>
        <li>
          <h3>Customizable Views</h3>
          <p>Switch between list and calendar views to suit your workflow.</p>
        </li>
        <li>
          <h3>Reminders & Notifications</h3>
          <p>Stay on top of renewals with timely alerts.</p>
        </li>
        <li>
          <h3>Collaboration Tools</h3>
          <p>Share access and manage your business collaboratively.</p>
        </li>
      </ul>
    </Section>
  );
};

const Section = styled.section`
  padding: 40px 20px;
  text-align: center;

  h1 {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 20px;

      h3 {
        font-size: 1.5em;
        color: #007bff;
        margin-bottom: 10px;
      }

      p {
        font-size: 1.2em;
        color: #666;
      }
    }
  }
`;

export default Features;
