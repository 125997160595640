import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
import { Carousel } from 'react-responsive-carousel';
import LogoFlex from '../../assets/Main/logo.jpeg';
import LogoFav from '../../assets/Main/logo.ico';
import s1 from '../../assets/Main/s1.jpg';
import s2 from '../../assets/Main/s2.jpg';
import s3 from '../../assets/Main/s3.jpg';
import s4 from '../../assets/Main/s4.jpg';
import playstore from '../../assets/Main/playstore.png';
import appstore from '../../assets/Main/appstore.png';

const AboutApp = () => {
  return (
    <Container>
      <Helmet> 
        <title>FlexiJoin - Manage Your Members</title>
        <link rel="icon" src={LogoFav} />
      </Helmet>      
      <LeftPanel>
        <Content>
          <LogoRow>
            <LogoContainer> {/* Replace About FlexiJoin with the logo */}
              <Logo src={LogoFlex} alt="FlexiJoin Logo" />
            </LogoContainer>
            <StoreLinks>
              <StoreLink href="https://play.google.com/apps/test/com.coolprogramer.FlexiJoin" target="_blank" rel="noopener noreferrer">
                <StoreLogo src={playstore} alt="Play Store" />
                Available on Play Store
              </StoreLink>
              <StoreLink href="#" target="_blank" rel="noopener noreferrer">
                <StoreLogo src={appstore} alt="App Store" />
                Coming Soon to Apple Store
              </StoreLink>
            </StoreLinks>
          </LogoRow>
          <SubHeading>Streamline Your Business Management and Growth</SubHeading>
          <Text>
            Welcome to FlexiJoin—a comprehensive platform designed for business owners in the fitness and wellness industry. Whether you run a gym, dance class, or yoga studio, FlexiJoin provides the tools you need to effectively manage your members and grow your business.
          </Text>
          <KeyFeatures>
            <FeatureTitle><strong>Key Features:</strong></FeatureTitle>
            <FeatureList>
              <FeatureItem><strong>Effortless Member Management:</strong> Simplify the onboarding, tracking, and renewing processes for your members.</FeatureItem>
              <FeatureItem><strong>Engagement Tools:</strong> Keep your clients informed and engaged with real-time notifications, updates, and communication channels.</FeatureItem>
              <FeatureItem><strong>Analytics & Insights:</strong> Gain valuable insights into your business performance with powerful analytics tools.</FeatureItem>
            </FeatureList>
            <Text>
              With FlexiJoin, you can focus more on delivering quality services while we handle the management side. Join us and watch your business thrive!
            </Text>
          </KeyFeatures>
        </Content>
      </LeftPanel>
      <RightPanel>
        <ScreenshotContainer>
          <Carousel showThumbs={false} infiniteLoop useKeyboardArrows autoPlay>
            <div>
              <Screenshot src={s1} alt="App Screenshot 1" />
            </div>
            <div>
              <Screenshot src={s2} alt="App Screenshot 2" />
            </div>
            <div>
              <Screenshot src={s3} alt="App Screenshot 3" />
            </div>
            <div>
              <Screenshot src={s4} alt="App Screenshot 4" />
            </div>
          </Carousel>
        </ScreenshotContainer>
      </RightPanel>
    </Container>
  );
};

// Styled Components
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 20px;
  height: 100vh;
  // background: linear-gradient(to bottom, #f0f0f0, #ffffff);
background: linear-gradient(to bottom, #007bff, #80d0ff);
  font-family: 'Arial', sans-serif;
`;

const LeftPanel = styled.div`
  flex: 1;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LogoRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const LogoContainer = styled.div`
  margin-right: 20px;
`;

const Logo = styled.img`
  max-width: 200px;
  height: auto;
`;

const Content = styled.div`
  background: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
`;

const SubHeading = styled.h2`
  font-size: 1.5em;
  color: #000; /* Black color */
  margin-bottom: 20px;
`;

const Text = styled.p`
  font-size: 1.2em;
  color: #000; /* Black color */
  margin-bottom: 20px;
`;

const KeyFeatures = styled.div`
  margin-bottom: 40px;
`;

const FeatureTitle = styled.h3`
  font-size: 1.8em; /* Increased size */
  font-weight: bold; /* Bold text */
  color: #000; /* Black color */
  margin-bottom: 10px;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0;
`;

const FeatureItem = styled.li`
  font-size: 1.2em;
  color: #000; /* Black color */
  margin-bottom: 10px;
`;

const StoreLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StoreLink = styled.a`
  display: flex;
  align-items: center;
  padding: 12px 20px;
  font-size: 1.1em;
  color: #333; /* Ensure contrast with yellow background */
  background-color: #FFD700; /* Yellow background color */
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #FFC107; /* Slightly darker yellow on hover */
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const StoreLogo = styled.img`
  max-width: 30px;
  height: auto;
  margin-right: 10px;
`;

const RightPanel = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ScreenshotContainer = styled.div`
  border: 2px solid black;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.9);
  width: 360px; /* Set the width for mobile screens */
  height: 640px; /* Set the height for mobile screens */
  overflow-y: scroll; /* Enable scrolling if the image doesn't fit */

  @media (max-width: 768px) {
    width: 100%; /* Adjust for medium screens */
    height: auto; /* Let the height adjust automatically */
  }

  @media (max-width: 480px) {
    width: 100%; /* Adjust for small screens */
    height: auto; /* Let the height adjust automatically */
  }
`;

const Screenshot = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain; /* Maintain aspect ratio within the container */
  border-radius: 10px;
  display: block;
`;

export default AboutApp;
