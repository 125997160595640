import React from 'react';
import styled from 'styled-components';

const AboutUs = () => {
  return (
    <Section>
      <h1>About Us</h1>
      <p>FlexiJoin was born out of a need to simplify member management for business owners in the fitness industry. Our mission is to provide a powerful, easy-to-use tool that helps you focus on what matters most—your members.</p>
      <h3>Our Team</h3>
      <p>Meet the passionate team behind FlexiJoin. [Add team photos and short bios]</p>
    </Section>
  );
};

const Section = styled.section`
  padding: 40px 20px;
  text-align: center;

  h1 {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2em;
    color: #666;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 1.5em;
    color: #007bff;
    margin-bottom: 10px;
  }
`;

export default AboutUs;
