import React from 'react';
import styled from 'styled-components';

const ThankYou = () => {
  return (
    <Section>
      <h1>Thank You!</h1>
      <p>Your account has been successfully confirmed.</p>
      <p>We're excited to have you on board. You can now start using FlexiJoin to manage your members efficiently.</p>
    </Section>
  );
};

const Section = styled.section`
  padding: 40px 20px;
  text-align: center;

  h1 {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2em;
    color: #666;
    margin-bottom: 20px;
  }
`;

const Button = styled.a`
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

export default ThankYou;
