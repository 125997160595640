import React from 'react';
import styled from 'styled-components';

const HowItWorks = () => {
  return (
    <Section>
      <h1>How It Works</h1>
      <Steps>
        <li><strong>Sign Up:</strong> Create an account and choose your subscription plan.</li>
        <li><strong>Set Up:</strong> Add your business details and start managing members.</li>
        <li><strong>Dashboard:</strong> Access your dashboard to view analytics and manage renewals.</li>
        <li><strong>Collaborate:</strong> Invite team members and assign roles.</li>
        <li><strong>Stay Updated:</strong> Set reminders and receive notifications for upcoming renewals.</li>
      </Steps>
    </Section>
  );
};

const Section = styled.section`
  padding: 40px 20px;
  text-align: center;

  h1 {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
  }
`;

const Steps = styled.ol`
  text-align: left;
  max-width: 600px;
  margin: 0 auto;

  li {
    font-size: 1.2em;
    color: #666;
    margin-bottom: 20px;

    strong {
      color: #007bff;
    }
  }
`;

export default HowItWorks;
