import React from 'react';
import styled from 'styled-components';

const Pricing = () => {
  return (
    <Section>
      <h1>Pricing</h1>
      <PricingTable>
        <PricingPlan>
          <h3>Basic Plan</h3>
          <p>Essential features for small businesses.</p>
          <Price>$29/month</Price>
        </PricingPlan>
        <PricingPlan>
          <h3>Pro Plan</h3>
          <p>Advanced features and analytics.</p>
          <Price>$59/month</Price>
        </PricingPlan>
        <PricingPlan>
          <h3>Enterprise Plan</h3>
          <p>Custom solutions for large businesses.</p>
          <Price>Contact us</Price>
        </PricingPlan>
      </PricingTable>
    </Section>
  );
};

const Section = styled.section`
  padding: 40px 20px;
  text-align: center;

  h1 {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
  }
`;

const PricingTable = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const PricingPlan = styled.div`
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  width: 200px;
  text-align: center;

  h3 {
    font-size: 1.5em;
    color: #007bff;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.2em;
    color: #666;
    margin-bottom: 20px;
  }
`;

const Price = styled.div`
  font-size: 1.5em;
  color: #333;
  font-weight: bold;
`;

export default Pricing;
