import React from 'react';
import styled from 'styled-components';

const Blog = () => {
  return (
    <Section>
      <h1>Blog</h1>
      <Articles>
        <li><a href="#">Effective Member Management Strategies</a></li>
        <li><a href="#">Boosting Gym Membership Renewals</a></li>
        <li><a href="#">Success Stories from FlexiJoin Users</a></li>
      </Articles>
    </Section>
  );
};

const Section = styled.section`
  padding: 40px 20px;
  text-align: center;

  h1 {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
  }
`;

const Articles = styled.ul`
  list-style: none;
  padding: 0;
  text-align: left;
  max-width: 600px;
  margin: 0 auto;

  li {
    font-size: 1.2em;
    color: #007bff;
    margin-bottom: 20px;

    a {
      text-decoration: none;
      color: #007bff;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export default Blog;
