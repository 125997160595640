import React from 'react';
import styled from 'styled-components';

const ContactUs = () => {
  return (
    <Section>
      <h1>Contact Us</h1>
      <Form>
        <label>
          Name
          <input type="text" name="name" />
        </label>
        <label>
          Email
          <input type="email" name="email" />
        </label>
        <label>
          Message
          <textarea name="message"></textarea>
        </label>
        <button type="submit">Submit</button>
      </Form>
      <p>Have questions or need support? Contact us at <a href="mailto:support@flexijoin.com">support@flexijoin.com</a> or call <a href="tel:+1234567890">+1 234 567 890</a>.</p>
    </Section>
  );
};

const Section = styled.section`
  padding: 40px 20px;
  text-align: center;

  h1 {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2em;
    color: #666;

    a {
      color: #007bff;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: 0 auto 20px;

  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 100%;

    input, textarea {
      padding: 10px;
      font-size: 1em;
      border: 1px solid #ddd;
      border-radius: 5px;
      margin-top: 5px;
    }

    textarea {
      resize: vertical;
      height: 100px;
    }
  }

  button {
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #0056b3;
    }
  }
`;

export default ContactUs;
