export function apidata(){
    const storeData = [
   
       {
           id:1,
           user_name: "Mahamudul",
           email: "mm@gmail.com",
           verified: 0,
           ban: 1,
           status: "offline",
           date:"1-10-23",
           
       },       
       {
           id:2,
           user_name: "Rana",
           email: "mm2@gmail.com",
           verified: 1,
           ban: 0,
           status: "online",
           date:"1-10-23",
           
       },       
   
   ]
   return {storeData}
   }